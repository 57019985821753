import React, { ReactNode, useContext, useMemo, useState } from "react";
import { useViewPanelLocalization } from "../localized/useViewPanelLocalization";
import { useProductAndProjectStateManager } from "@internal/utils-product-and-project-state";
import { LocalizedProvider } from "../localized";

interface ViewPanelContext {
    showGridlines: boolean;
    setShowGridlines: React.Dispatch<React.SetStateAction<boolean>>;
    showSafetyArea: boolean;
    setShowSafetyArea: React.Dispatch<React.SetStateAction<boolean>>;
    showBleed: boolean;
    setShowBleed: React.Dispatch<React.SetStateAction<boolean>>;
    showRulers: boolean;
    setShowRulers: React.Dispatch<React.SetStateAction<boolean>>;
    shouldShowPanelSections: boolean;
}
interface ViewPanelProviderProps {
    children: ReactNode | ReactNode[];
    shouldShowPanelSections: boolean;
}

const context = React.createContext<ViewPanelContext | undefined>(undefined);

export const useViewPanelContext = () => {
    const result = useContext(context);
    if (!result) {
        throw Error("Please call this within ViewPanelContext");
    }
    return result;
};

export const ViewPanelProvider = ({ children, shouldShowPanelSections }: ViewPanelProviderProps) => {
    const { Provider } = context;
    const [showRulers, setShowRulers] = useState(true);
    const [showSafetyArea, setShowSafetyArea] = useState(true);
    const [showBleed, setShowBleed] = useState(true);
    const [showGridlines, setShowGridlines] = useState(false);

    const localizedValues = useViewPanelLocalization();
    const { locale } = useProductAndProjectStateManager().data;

    const contextValue = useMemo(() => {
        return {
            showRulers,
            setShowRulers,
            showSafetyArea,
            setShowSafetyArea,
            showBleed,
            setShowBleed,
            showGridlines,
            setShowGridlines,
            shouldShowPanelSections
        };
    }, [showRulers, showSafetyArea, showBleed, showGridlines, shouldShowPanelSections]);

    return (
        <LocalizedProvider localizedValues={localizedValues} locale={locale}>
            <Provider value={contextValue}>{children}</Provider>
        </LocalizedProvider>
    );
};
ViewPanelProvider.displayName = "ViewPanelProvider";
